import React, { useCallback, useEffect, useState } from 'react';
import { HomeContainer } from '@pages/Home/styles';
import { Edital, EditaisParticipantes } from '@libs/types';
import { getEditais, getDadosSuporte } from '@api/EditaisService';
import { CardEdital } from '@components/CardEdital';
import { Paginator } from '@components/Paginator';
import { TextInput } from '@components/TextInput';
import { Select } from '@components/Select';
import { states } from '@utils/states';
import { editaisEmptyMock } from '../../utils/editaisMock';
import { Header } from '@components/Header';
import { Spin } from 'antd';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { segments } from '@pages/Home/segments';
import useMediaQuery from '@mui/material/useMediaQuery';
import { FlaskIcon } from '@assets/icons/flask';
import { dadosSuporteMock } from '@utils/dadosSuporteMock';
import Slider from '@assets/slider.svg';

export type PaginationType = {
  take: number;
  skip: number;
};

export type Option = {
  label: string;
  value: string;
};

type SelectOptions = {
  estado: Option[];
  modalidade: Option[];
  orgaoPromotor: Option[];
  EditaisEncerrados: Option[];
  objeto: Option[];
  segmento: Option[];
  cidades: Option[];
};

const PAGINATION: PaginationType = {
  take: 10,
  skip: 0,
};

export const Home = ({ edital }) => {
  const [loading, setLoading] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [participantes, setParticipantes] =
    useState<EditaisParticipantes | null>(null);
  const [allParticipantes, setAllParticipantes] =
    useState<EditaisParticipantes | null>(null);
  const [editalCurrent, setEditalCurrent] = useState<Edital | null>(edital);

  const [cidadesStates, setCidadesStates] = useState<
    { label: any; value: any }[]
  >([]);
  const [selectOptions, setSelectOptions] = useState<SelectOptions>({
    estado: states,
    cidades: cidadesStates,
  } as SelectOptions);
  const [search, setSearch] = useState({
    numeroEdital: '',
    estado: '',
    modalidade: '',
    orgao: '',
    editaisEncerrados: '',
    palavraChave: '',
    objetoId: '',
    objeto: '',
    cidade: '',
    segmento: '',
  });
  const [orgaoSuggestions, setOrgaoSuggestions] = useState<string[]>([]);
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [isDivTwoVisible, setIsDivTwoVisible] = useState(false); // State for divtwo visibility
  const isSmallScreen = useMediaQuery('(max-width: 768px)');
  const [filterText, setFilterText] = useState('');

  const [selectedOrgao, setSelectedOrgao] = useState<any>();

  const sortedOrgaoSuggestions = orgaoSuggestions
    .map((item) => ({ label: item, firstLetter: item[0].toUpperCase() }))
    .sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter));

  const toggleDivTwo = () => {
    setIsDivTwoVisible(!isDivTwoVisible);
  };

  const handleSelectOption = (value: string | number, name: string) => {
    setSearch({
      ...search,
      [name]: value,
    });
  };

  const handleGetSupport = () => {
    const { modalidade, ramoAtividade } = dadosSuporteMock;

    type Option = {
      label: string;
      value: string | number; // Allow both string and number
    };

    // Step 1: Define the specific modalidade options with the given values and labels
    const modalidadeOptions = [
      {
        label: 'Concorrência',
        value: 1,
      },
      {
        label: 'Pregão (Setor público)',
        value: 3,
      },
      {
        label: 'Dispensa',
        value: 5,
      },
      {
        label: 'Credenciamento',
        value: 6,
      },
    ] as Option[]; // Cast to the Option[] type

    // Step 2: Set the modalidade options in the state
    setSelectOptions((prevState) => ({
      ...prevState,
      modalidade: modalidadeOptions,
    }));

    // Step 3: Define and set situacao options
    const situacaoOptions = [
      {
        label: 'Não iniciado',
        value: '1',
      },
      {
        label: 'Em execução',
        value: '2',
      },
      {
        label: 'Encerrado',
        value: '3',
      },
    ] as Option[];

    setSelectOptions((prevState) => ({
      ...prevState,
      EditaisEncerrados: situacaoOptions,
    }));

    // Step 4: Map and set objeto options based on ramoAtividade
    if (ramoAtividade) {
      const objetoOptions = ramoAtividade.map((objeto: any) => ({
        label: objeto.descricao,
        value: objeto.uniqueId,
      }));

      setSelectOptions((prevState) => ({
        ...prevState,
        objeto: objetoOptions,
      }));
    }
  };
  const handleClear = () => {
    const clearButton = document.querySelector<HTMLElement>(
      '.MuiAutocomplete-clearIndicator',
    );
    if (clearButton) {
      clearButton.click(); // Simulate a click on the clear button
    }
    // Clear the search state
    setSearch({
      numeroEdital: '',
      estado: '',
      modalidade: '',
      orgao: '',
      editaisEncerrados: '',
      palavraChave: '',
      objetoId: '',
      objeto: '',
      cidade: '',
      segmento: '',
    });
    setSelectedOrgao(null);
    setCurrentPage(0);
  };

  const handleFilter = useCallback(
    (event?: React.FormEvent<HTMLFormElement>) => {
      event && event.preventDefault();
      let queryString = `?skip=0&take=99999999`;

      if (search.modalidade) {
        queryString += `&modalidade=${encodeURIComponent(search.modalidade)}`;
      }
      if (search.numeroEdital) {
        queryString += `&numeroEdital=${search.numeroEdital}`;
      }
      if (search.objeto) {
        queryString += `&objetoId=${search.objeto}`;
      }
      if (selectedOrgao) {
        queryString += `&orgao=${selectedOrgao.label}`;
      }
      if (search.estado) {
        queryString += `&estado=${search.estado}`;
      }
      if (search.editaisEncerrados) {
        queryString += `&situacao=${search.editaisEncerrados}`;
      }
      if (search.palavraChave) {
        queryString += `&palavraChave=${search.palavraChave}`;
      }
      if (search.cidade) {
        queryString += `&cidade=${encodeURIComponent(search.cidade)}`;
      }
      if (search.segmento) {
        queryString += `&segmento=${search.segmento}`;
      }

      setLoading(true);

      fetch(`https://jornal.prod.back.mageserver.com.br/edital?${queryString}`)
        .then((response) => response.json())
        .then((result) => {
          let resultFiltered = result.content;

          const sortedEditais = result.content.sort((a, b) => {
            const dateA = a.publishedAt ? new Date(a.publishedAt).getTime() : 0;
            const dateB = b.publishedAt ? new Date(b.publishedAt).getTime() : 0;
            return dateB - dateA;
          });

          setAllParticipantes({
            editais: sortedEditais,
            count: Math.ceil((sortedEditais.length ?? 1) / PAGINATION.take),
          });
          setParticipantes({
            editais: sortedEditais.slice(0, PAGINATION.take),
            count: Math.ceil((sortedEditais.length ?? 1) / PAGINATION.take),
          });
        })
        .catch((error) => {
          setAllParticipantes(editaisEmptyMock);
          setParticipantes(editaisEmptyMock);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [search],
  );

  const handleOrgaoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event) return;
    const nomeOrgao = event.currentTarget.value;
    const queryString = `?skip=0&take=99999999`;
    setSearch({ ...search, orgao: nomeOrgao });
    setSelectedOrgao({ label: nomeOrgao, value: nomeOrgao });
    fetch(`https://jornal.prod.back.mageserver.com.br/edital?${queryString}`)
      .then((response) => response.json())
      .then((result) => {
        const orgaoNames = result.content
          .map((edital: any) => edital.nomeDoOrgaoPromotor)
          .filter((item: any, pos: any, self: string | any[]) => {
            return (
              self.indexOf(item) === pos &&
              item.toLowerCase().includes(nomeOrgao.toLowerCase())
            );
          });
        setOrgaoSuggestions(orgaoNames);
      });
  };

  useEffect(() => {
    if (search.estado) {
      // Your logic here, e.g., fetch data based on the selected estado
      const cidadesTemp: { label: any; value: any }[] = [];

      fetch(
        `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${search.estado}/municipios`,
      )
        .then((response) => response.json())
        .then((result) => {
          result.map((item: any) => {
            cidadesTemp.push({ label: item.nome, value: item.nome });
          });

          setCidadesStates(cidadesTemp);
        });
    }
  }, [search.estado]); // This effect runs when search.estado changes

  useEffect(() => {
    handleGetSupport();
    handleFilter();

    fetch(
      `https://jornal.prod.back.mageserver.com.br/edital?skip=0&take=999999999`,
    )
      .then((response) => response.json())
      .then((result) => {
        const sortedEditais = result.content.sort((a, b) => {
          const dateA = a.publishedAt ? new Date(a.publishedAt).getTime() : 0;
          const dateB = b.publishedAt ? new Date(b.publishedAt).getTime() : 0;
          return dateB - dateA;
        });

        sortedEditais.map((edital) => {
          console.log(edital.relatoriosDoEdital);
        });

        const orgaoNames = sortedEditais
          .map((edital) => edital.nomeDoOrgaoPromotor)
          .filter((item, pos, self) => self.indexOf(item) === pos);

        setOrgaoSuggestions(orgaoNames);
        setAllParticipantes({
          editais: sortedEditais,
          count: Math.ceil((sortedEditais.length ?? 1) / PAGINATION.take),
        });
        setParticipantes({
          editais: sortedEditais.slice(0, PAGINATION.take),
          count: Math.ceil((sortedEditais.length ?? 1) / PAGINATION.take),
        });
      });
  }, []);

  const handlePageChange = (page: PaginationType) => {
    if (
      page.skip === Math.ceil((participantes?.count ?? 1) - 1 / PAGINATION.take)
    )
      return;

    setCurrentPage(page.skip);
    if (allParticipantes) {
      const startIndex = page.skip * page.take;
      const endIndex = startIndex + page.take;
      setParticipantes({
        editais: allParticipantes.editais.slice(startIndex, endIndex),
        count: allParticipantes.count,
      });
    }
  };

  const handleFilterTextChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setFilterText(event.target.value);
  };

  const borderRadiusStyle = {
    borderRadius: isDivTwoVisible
      ? '0px'
      : window.innerWidth < 900
        ? '10px'
        : '0px 0px 10px 10px',
    height: window.innerWidth < 900 ? '120%' : 'auto',
    padding: window.innerWidth < 900 ? '1.2em' : 'auto',
  };

  const handleFilterButtonClick = () => {
    setSearch({
      ...search,
      palavraChave: filterText,
    });
    handleFilter();
  };

  return (
    <HomeContainer>
      <Header />
      <section>
        <header>
          <div className="titles">
            <h4>Jornal do Licitante</h4>
            <h6>Editais Publicados</h6>
          </div>
          {!isSmallScreen && (
            <div className="newsletter">
              <span>Quer receber os Editais por e-mail?</span>
              <a href="https://novobbmnet.com.br/cadastro-jornal/">
                <button style={{ width: '100%' }} className="register">
                  Cadastre aqui
                </button>
              </a>
            </div>
          )}
        </header>
      </section>
      <main>
        <form
          style={{
            display: 'contents',
            width: 'inherit',
            backgroundColor: '#f8f4f4',
          }}
          onSubmit={handleFilter}
        >
          {window.innerWidth > 900 && (
            <div
              className="divextra"
              style={{
                backgroundColor: '#004aa0',
                width: '100%',
                paddingTop: '10px',
                borderRadius: '12px 12px 0 0',
              }}
            >
              {' '}
              <span
                style={{
                  color: 'white',
                  marginLeft: '2.5em',
                  fontSize: '26px',
                  fontWeight: 'bold',
                }}
              >
                Filtros
              </span>
            </div>
          )}
          <div className="divone" style={borderRadiusStyle}>
            <TextInput
              let
              onChange={(event) =>
                handleSelectOption(event.currentTarget.value, 'palavraChave')
              }
              value={search.palavraChave}
              label={
                window.innerWidth > 900 ? (
                  <span
                    style={{
                      color: 'white',
                      paddingLeft: '5em',
                      paddingBottom: '10px',
                    }}
                  >
                    Buscar
                  </span>
                ) : (
                  <span></span>
                )
              }
              placeholder="Palavra Chave"
              style={{
                width: window.innerWidth < 900 ? '100% !important' : '40em',
                alignSelf: 'center',
                height: window.innerWidth < 900 ? '2.5em' : '2.5em',
                borderRadius: '22px',
                borderColor: 'white',
                marginRight: '10px',
                marginLeft: '5em',
                paddingLeft: '2em',
                marginBottom: '26px',
                marginTop: '10px',
                fontSize: window.innerWidth < 900 ? '20px' : '18px',
                color: 'black',
                boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
              }}
              className="palavra-chave"
            />
            <div className="blue">
              {window.innerWidth > 900 && (
                <button
                  className="filter-button"
                  type="submit"
                  style={{
                    fontSize: '20px',
                    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                    borderRadius: '30px',
                    width: '6em',
                    height: '2em',
                    margin: '0',
                  }}
                >
                  Filtrar
                </button>
              )}
              {window.innerWidth > 900 && (
                <button
                  className="filter-button"
                  style={{
                    fontSize: '20px',
                    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                    borderRadius: '30px',
                    width: '6em',
                    height: '2em',
                    margin:
                      window.innerWidth < 900 ? '0.5em' : '0 0.5em 0 0.5em',
                  }}
                  onClick={handleClear}
                >
                  Limpar
                </button>
              )}
            </div>

            {window.innerWidth <= 900 && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  margin: '1em',
                }}
              >
                <button
                  className="filter-button"
                  type="submit"
                  style={{
                    fontSize: '20px',
                    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                    borderRadius: '30px',
                    width: '6em',
                    height: '2em',
                    margin: '0',
                    alignSelf: 'center',
                    justifySelf: 'center',
                  }}
                >
                  Filtrar
                </button>
                <button
                  className="filter-button"
                  style={{
                    fontSize: '20px',
                    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                    borderRadius: '30px',
                    width: '6em',
                    height: '2em',
                    margin:
                      window.innerWidth < 900 ? '0.5em' : '0 0.5em 0 0.5em',
                  }}
                  onClick={handleClear}
                >
                  Limpar
                </button>

                <div>
                  <img
                    className="slider"
                    src={Slider}
                    alt=""
                    width={32}
                    style={{ filter: 'invert(100%)' }}
                  />
                  <button
                    className="mais-filtros-button"
                    onClick={toggleDivTwo}
                    style={{
                      marginLeft: window.innerWidth < 900 ? '10px' : '4em',
                      padding:
                        window.innerWidth < 900
                          ? '0px 0px 0px 10px'
                          : '5px 10px',
                      fontSize: '22px',
                    }}
                  >
                    Mais filtros
                  </button>
                </div>
              </div>
            )}

            {window.innerWidth > 900 && (
              <div className="mais-filtros-div">
                <img
                  className="slider"
                  src={Slider}
                  alt=""
                  width={32}
                  style={{ filter: 'invert(100%)' }}
                />
                <button
                  className="mais-filtros-button"
                  onClick={toggleDivTwo}
                  style={{
                    marginLeft: window.innerWidth < 900 ? '0em' : '4em',
                    padding: window.innerWidth < 900 ? '0px' : '5px 10px',
                    fontSize: '22px',
                  }}
                >
                  Mais filtros
                </button>
              </div>
            )}
          </div>

          {isDivTwoVisible && (
            <div
              className="divtwo"
              style={{
                alignItems: window.innerWidth < 900 ? 'start' : 'auto',
              }}
            >
              <Autocomplete
                options={sortedOrgaoSuggestions}
                getOptionLabel={(option) => option.label}
                onInputChange={handleOrgaoChange}
                value={selectedOrgao}
                noOptionsText={'Nenhum Resultado'}
                onChange={(event, newValue) => setSelectedOrgao(newValue)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="orgao-input"
                    placeholder="Orgão"
                    variant="outlined"
                    sx={{
                      '& .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiAutocomplete-inputRoot':
                        {
                          padding: '0',
                        },
                      '& .MuiInputBase-input': {
                        color: 'black',
                        fontFamily: 'Montserrat',
                        fontSize: '2.2em',
                        fontWeight: '500',
                        marginRight: '0em',
                        backgroundColor: 'white',
                        padding: '0',
                        borderRadius: '21px',
                        textAlign: 'center',
                        '@media (max-width: 900px)': {
                          width: '230px !important',
                          height: '31px !important', // Adjust the width as needed
                        },
                      },
                      '& .MuiInputBase-root': {
                        padding: '0',
                        backgroundColor: 'white',
                        borderRadius: '21px',
                        width: '230px',
                        '@media (max-width: 900px)': {
                          width: '70% !important',
                          height: '31px !important', // Adjust the width as needed
                        },
                      },
                      '& .MuiAutocomplete-hasPopupIcon': {
                        padding: '0',
                      },
                      '& .MuiAutocomplete-input': {
                        padding: '0',
                        '@media (max-width: 900px)': {
                          width: '70% !important',
                          height: '31px !important', // Adjust the width as needed
                        },
                      },
                      '& .MuiSvgIcon-root': {
                        color: 'transparent',
                      },
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          padding: '0',
                          width: '100%',
                          color: 'transparent',
                          borderColor: 'transparent',
                        },
                      },
                      '& .MuiInputLabel-root': {
                        color: 'black',
                        fontSize: '3rem',
                        fontFamily: 'Montserrat',
                      },
                      '& .MuiButtonBase-root': {
                        marginRight: '1.5em',
                      },
                    }}
                  />
                )}
              />
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignContent: 'center',
                }}
              >
                {' '}
                <TextInput
                  className="numero"
                  onChange={(event) =>
                    handleSelectOption(
                      event.currentTarget.value,
                      'numeroEdital',
                    )
                  }
                  value={search.numeroEdital}
                  label=""
                  placeholder="No. Edital"
                  style={{
                    width:
                      window.innerWidth < 900 ? '100px !important' : '230px',
                    padding: '14px',
                    fontSize: '1.4rem',
                    background: 'white',
                    borderRadius: '21px',
                    color: 'black',
                    fontWeight: '500',
                    alignSelf: 'center',
                    textAlign: 'center',
                    fontFamily: 'Montserrat',
                  }}
                />
              </div>
              <div className="selector">
                <Select
                  label=""
                  onChange={(value) => handleSelectOption(+value, 'modalidade')}
                  value={search.modalidade}
                  options={selectOptions?.modalidade ?? []}
                  placeholder="Modalidade"
                  style={{ width: '230px' }}
                />
              </div>
              <div className="selector">
                <Select
                  label=""
                  onChange={(value) =>
                    handleSelectOption(value, 'editaisEncerrados')
                  }
                  value={search.editaisEncerrados}
                  options={selectOptions?.EditaisEncerrados ?? []}
                  placeholder="Situação"
                  style={{ width: '230px' }}
                />
              </div>
              <div className="selector">
                <Select
                  label=""
                  onChange={(value) => handleSelectOption(value, 'estado')}
                  value={search.estado}
                  options={selectOptions?.estado ?? []}
                  placeholder="Estado"
                  style={{ width: '230px' }}
                />
              </div>
              <div className="selector">
                <Select
                  label=""
                  onChange={(value) => handleSelectOption(value, 'cidade')}
                  value={search.cidade}
                  options={cidadesStates}
                  placeholder="Cidade"
                />
              </div>
              <div className="selector">
                <Select
                  label=""
                  onChange={(value) => handleSelectOption(value, 'objeto')}
                  value={search.objeto}
                  options={selectOptions?.objeto ?? []}
                  placeholder="Objeto"
                  style={{ width: '230px' }}
                />
              </div>
              <div className="selector">
                <Select
                  label=""
                  onChange={(value) => handleSelectOption(value, 'segmento')}
                  value={search.segmento}
                  options={segments}
                  placeholder="Segmento"
                  style={{ width: '230px' }}
                />
              </div>
            </div>
          )}
        </form>
        <div
          className="caderno"
          style={{
            width: '100%',
            height: '30%',
            marginTop: '1em',
            marginBottom: '1em',
            justifyContent: 'left',
            marginLeft: '1em',
            backgroundColor: '#f8f4f4',
            boxSizing: 'border-box',
          }}
        >
          <span
            style={{
              color: '#084ca4',
              fontWeight: 'bold',
              fontFamily: 'Montserrat',
              fontSize: '2em',
              marginLeft: '2em',
            }}
          >
            {search.palavraChave && `${search.palavraChave} `}
          </span>
        </div>
        <div className="divthree">
          {loading ? (
            <Spin size="large" />
          ) : (
            <div className="card-list">
              {participantes?.editais.length > 0 ? (
                participantes?.editais.map((edital) => (
                  <CardEdital key={edital.id} edital={edital} />
                ))
              ) : (
                <div className="no-results">
                  <span
                    style={{
                      color: '#084ca4',
                      fontWeight: 'bold',
                      fontFamily: 'Montserrat',
                      fontSize: '2em',
                      marginLeft: '4em',
                      paddingBottom: '2em',
                    }}
                  >
                    Nenhum resultado encontrado
                  </span>
                </div>
              )}
            </div>
          )}
        </div>
      </main>
      <footer>
        <Paginator
          onPageChange={handlePageChange}
          currentPage={currentPage}
          totalPages={participantes?.count ?? 1}
          take={PAGINATION.take}
        />
      </footer>
    </HomeContainer>
  );
};
